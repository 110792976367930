export const NODES_LIST: {
  id: string;
  name: langData;
  flag: string;
  protocols: string[];
  type: 'marzban' | 'proxy';
}[] = [
  {
    id: 'aeza-stockholm',
    name: 'locationStockholm',
    flag: '🇸🇪',
    protocols: ['Vless', 'SS'],
    type: 'marzban',
  },
  {
    id: 'aeza-stockholm-http',
    name: 'locationStockholm',
    flag: '🇸🇪',
    protocols: ['HTTP'],
    type: 'proxy',
  },
];
