import React from 'react';
import { BasePage } from './base';
import { langString } from '../../lang';
import { PROJECT_NAME, PROJECT_BOT } from '../../config/project';
import { navIcon } from '../modules/icon';
import { CurrencyConvert, CurrencyConvertText } from '../../config/currency';
import axios from 'axios';

const state = {
  amount: 0,
  userID: '',
};

export class PaymentPage extends BasePage<
  typeof state,
  { usrID?: string; usrAmount?: number }
> {
  state: Readonly<typeof state> = { ...state };

  constructor(props: any) {
    super(props);
    requestAnimationFrame(() => {
      this.setState({
        userID: this.props.usrID || '',
        amount: this.props.usrAmount || 0,
      });
    });
  }

  PaySumButtons(...ammounts: number[]) {
    return (
      <div className="quick-amount-row-1">
        {ammounts.map((value, i) => {
          return (
            <button
              key={i}
              type="button"
              className="btn quick-amount-btn"
              onClick={e => {
                e.preventDefault();
                this.setState({
                  amount: CurrencyConvert(value, this.currency),
                });
              }}
            >
              {CurrencyConvertText(value, this.currency, true)}
            </button>
          );
        })}
      </div>
    );
  }

  render() {
    return (
      <>
        <section id="payment" style={{ marginTop: '10px' }}>
          <div className="container">
            <h2 style={{ textAlign: 'center' }}>
              {this.LangString('pagePayTitle')}
            </h2>
            <div className="payment-wrapper">
              <div className="payment-form">
                <h2>{this.LangString('pagePayBalance')}</h2>
                <form id="payment-form">
                  <div className="form-group">
                    <label htmlFor="user-id" className="label-large">
                      {this.LangString('pagePayID')}
                    </label>
                    <input
                      type="text"
                      id="user-id"
                      name="user-id"
                      className="form-control input-large"
                      placeholder={this.LangString('pagePayIDPlaceholder')}
                      value={this.state.userID}
                      onChange={e => {
                        const re = /^[0-9\b]+$/;

                        // if value is not blank, then test the regex

                        if (e.target.value === '' || re.test(e.target.value)) {
                          this.setState({ userID: e.target.value });
                        }
                      }}
                    />
                    <label
                      htmlFor="amount"
                      className="label-large amount-label"
                    >
                      {this.LangString('pagePayAmount')}
                    </label>
                    <input
                      type="number"
                      id="amount"
                      name="amount"
                      value={this.state.amount}
                      min="100"
                      className="form-control input-large"
                      required
                      onChange={e => {
                        let val = e.currentTarget.valueAsNumber;
                        if (!val || val < 100) {
                          val = 100;
                        }
                        this.setState({ amount: val });
                      }}
                    />
                    <div className="quick-amount-buttons">
                      {this.PaySumButtons(1, 5, 10)}
                    </div>
                    <div className="quick-amount-buttons">
                      {this.PaySumButtons(20, 50, 100)}
                    </div>
                  </div>

                  <a
                    href="#"
                    id="payment-link"
                    className={`btn-primary ${
                      !this.state.userID ||
                      !this.state.amount ||
                      this.state.amount < 0
                        ? 'disabled'
                        : ''
                    }`}
                    onClick={e => {
                      e.preventDefault();
                      if (
                        !this.state.userID ||
                        !this.state.amount ||
                        this.state.amount < 0
                      )
                        return;
                      axios
                        .get(
                          `http://${
                            location.host === 'localhost'
                              ? 'localhost'
                              : (process.env.REMOTE_DOMAIN_2 as string)
                          }:80/payments/create/${this.state.userID}/${this.state.amount}/${this.currency}`
                        )
                        .then(res => {
                          const data = res.data;
                          console.log(data);

                          if (!data.status) return;
                          window.open(data.url, '_blank');
                        });
                    }}
                  >
                    <b>{this.LangString('pagePayButton')}</b>
                  </a>
                </form>
              </div>
              <div className="payment-info">
                <h3>{this.LangString('pageFAQId')}</h3>
                <ol>
                  <li>{this.LangString('pageFAQStep1')}</li>
                  <li>{this.LangString('pageFAQStep2')}</li>
                  <li>{this.LangString('pageFAQStep3')}</li>
                  <li>{this.LangString('pageFAQStep4')}</li>
                </ol>
                <div className="support-link">
                  <a
                    href={`https://t.me/${PROJECT_BOT}`}
                    style={{ color: '#666' }}
                  >
                    {this.LangString('pageFAQOpen')}
                  </a>
                  <br />
                  <img
                    src={navIcon('qrbot.png')}
                    alt={this.LangString('pageFAQQR')}
                    style={{ maxWidth: '232px', borderRadius: '10px' }}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}
